<template>
  <div>
    <el-page-header icon="el-icon-arrow-left" title="" @back="goPath('/course/detail/' + cur_uuid)" :content="title" class="header"></el-page-header>
    <div class="content_wrap">
      <!-- 索引 -->
      <div class="left">
        <el-tabs v-model="activeName" class="top_tabs" @tab-click="initErrorPage">
          <el-tab-pane label="全部" name="total">
            <div class="wrap">
              <div v-if="judgeObj.length > 0">
                <h4 class="title">一、判断题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of judgeObj" :key="item.id">
                    <div :class="{'index': true, 'success': item.is_right === true, 'error': item.is_right === false}" @click="anchorLinkTo('judge', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="singleChoiceObj.length > 0">
                <h4 class="title" v-if="judgeObj.length > 0">二、单选题</h4>
                <h4 class="title" v-else>一、单选题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of singleChoiceObj" :key="item.id">
                    <div :class="{'index': true, 'success': item.is_right === true, 'error': item.is_right === false}" @click="anchorLinkTo('single', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="multiChoiceObj.length > 0">
                <h4 class="title" v-if="judgeObj.length > 0 && singleChoiceObj.length > 0">三、多选题</h4>
                <h4 class="title" v-else-if="judgeObj.length > 0 || singleChoiceObj.length > 0">二、多选题</h4>
                <h4 class="title" v-else>一、多选题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of multiChoiceObj" :key="item.id">
                    <div :class="{'index': true, 'success': item.is_right === true, 'error': item.is_right === false}" @click="anchorLinkTo('multi', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="错题集" name="wrong">
            <div class="wrap">
              <div v-if="errorJudgeObj.length > 0">
                <h4 class="title">一、判断题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of errorJudgeObj" :key="item.id">
                    <div :class="{'index': true, 'error': true}" @click="anchorErrorLinkTo('judge', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="errorSingleChoiceObj.length > 0">
                <h4 class="title" v-if="judgeObj.length > 0">二、单选题</h4>
                <h4 class="title" v-else>一、单选题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of errorSingleChoiceObj" :key="item.id">
                    <div :class="{'index': true, 'error': true}" @click="anchorErrorLinkTo('single', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="errorMultiChoiceObj.length > 0">
                <h4 class="title" v-if="judgeObj.length > 0 && singleChoiceObj.length > 0">三、多选题</h4>
                <h4 class="title" v-else-if="judgeObj.length > 0 || singleChoiceObj.length > 0">二、多选题</h4>
                <h4 class="title" v-else>一、多选题</h4>
                <el-row :gutter="15">
                  <el-col :span="3" v-for="(item, index) of errorMultiChoiceObj" :key="item.id">
                    <div :class="{'index': true, 'error': true}" @click="anchorErrorLinkTo('multi', item.id)">
                      <i class="iconfont icon-biaoqian1-01" v-if="userTags.indexOf(item.id) >= 0"></i>
                      {{ index + 1 }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="bottom">
          <el-button type="primary" icon="el-icon-refresh-right" size="small" @click="restart">重新做题</el-button>
        </div>
      </div>
      <!-- 习题 -->
      <div class="right ueditor_style" v-if="activeName == 'total'">
        <h4 v-if="judgeChoice.length > 0 && questionNos.judgeNos[judgeChoice[0].id] == 1">一、判断题</h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in judgeChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.judgeNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-radio-group v-model="item.selected" @change="onSubmit(item)" :disabled="item.user_answer">
                    <el-radio label="1" key="1">
                      <div class="opts_content"><p>对</p></div>
                    </el-radio>
                    <el-radio label="0" key="0">
                      <div class="opts_content"><p>错</p></div>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="analysis" v-if="item.user_answer">
                  <div class="answer">
                    <span class="error" v-if="item.user_answer.is_right === false"><i class="el-icon-close"></i>回答错误</span>
                    <span class="success" v-if="item.user_answer.is_right === true"><i class="el-icon-check"></i>回答正确</span>
                    <span>正确答案：{{ JSON.parse(item.answer)[0] == 1 ? '对' : '错' }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h4 v-if="singleChoice.length > 0 && questionNos.singleChoiceNos[singleChoice[0].id] == 1">
          <span v-if="judgeObj.length > 0">二、单选题</span>
          <span v-else>一、单选题</span>
        </h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in singleChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.singleChoiceNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-radio-group v-model="item.selected" :disabled="item.user_answer" @change="onSubmit(item)">
                    <el-radio :label="option.identification" v-for="option in item.options" :key="option.identification">
                      <div class="opts_no">{{option.identification}}. </div>
                      <div class="opts_content" v-html="option.option"></div>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="analysis" v-if="item.user_answer">
                  <div class="answer">
                    <span class="error" v-if="item.user_answer.is_right === false"><i class="el-icon-close"></i>回答错误</span>
                    <span class="success" v-if="item.user_answer.is_right === true"><i class="el-icon-check"></i>回答正确</span>
                    <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h4 v-if="multipleChoice.length > 0 && questionNos.multiChoiceNos[multipleChoice[0].id] == 1">
          <span v-if="singleChoiceObj.length > 0 && judgeObj.length > 0">三、多选题</span>
          <span v-else-if="singleChoiceObj.length > 0 || judgeObj.length > 0">二、多选题</span>
          <span v-else>一、多选题</span>
        </h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in multipleChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.multiChoiceNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-checkbox-group v-model="item.selected" :disabled="item.user_answer">
                    <el-checkbox :label="option.identification" v-for="option in item.options" :key="option.identification" >
                      <div class="opts_no">{{option.identification}}. </div>
                      <div class="opts_content" v-html="option.option"></div>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="btn">
                  <el-button type="primary" size="small" @click="onSubmit(item)">确定</el-button>
                </div>
                <div class="analysis" v-if="item.user_answer">
                  <div class="answer">
                    <span class="error" v-if="item.user_answer.is_right === false"><i class="el-icon-close"></i>回答错误</span>
                    <span class="success" v-if="item.user_answer.is_right === true"><i class="el-icon-check"></i>回答正确</span>
                    <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <Pagination :pager="pager" @getPager="getPager" ref="pagination"></Pagination>
      </div>
      <div class="right ueditor_style" v-else-if="activeName == 'wrong'">
        <h4  v-if="errorJudgeChoice.length > 0 && questionNos.errorJudgeNos[errorJudgeChoice[0].id] == 1">一、判断题</h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in errorJudgeChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.errorJudgeNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-radio-group v-model="item.selected" :disabled="true">
                    <el-radio label="1" key="1"><div class="opts_content"><p>对</p></div></el-radio>
                    <el-radio label="0" key="0"><div class="opts_content"><p>错</p></div></el-radio>
                  </el-radio-group>
                </div>
                <div class="analysis" v-if="item.user_answer">
                  <div class="answer">
                    <span class="error"><i class="el-icon-close"></i>回答错误</span>
                    <span>正确答案：{{ JSON.parse(item.answer)[0] == 1 ? '对' : '错' }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h4 v-if="errorSingleChoice.length > 0 && questionNos.errorSingleChoiceNos[errorSingleChoice[0].id] == 1">
          <span v-if="judgeObj.length > 0">二、单选题</span>
          <span v-else>一、单选题</span>
        </h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in errorSingleChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.errorSingleChoiceNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-radio-group v-model="item.selected" :disabled="true">
                    <el-radio :label="option.identification" v-for="option in item.options" :key="option.identification">
                      <div class="opts_no">{{option.identification}}. </div>
                      <div class="opts_content" v-html="option.option"></div>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div class="analysis">
                  <div class="answer">
                    <span class="error"><i class="el-icon-close"></i>回答错误</span>
                    <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h4 v-if="errorMultipleChoice.length > 0 && questionNos.errorMultiChoiceNos[errorMultipleChoice[0].id] == 1">
          <span v-if="singleChoiceObj.length > 0 && judgeObj.length > 0">三、多选题</span>
          <span v-else-if="singleChoiceObj.length > 0 || judgeObj.length > 0">二、多选题</span>
          <span v-else>一、多选题</span>
        </h4>
        <ul class="exercise_list">
          <li v-for="(item, index) in errorMultipleChoice" :key="item.id" :ref="`question_${item.id}`">
            <div class="tag">
              <i :class="{'iconfont': true, 'icon-biaoqian1-01':true, 'is_tag': userTags.indexOf(item.id) >= 0}" @click="tagQuestion(item.id)"></i>
            </div>
            <div class="content">
              <div class="no">{{ questionNos.errorMultiChoiceNos[item.id] }}.</div>
              <div class="main">
                <div class="stem" v-html="item.stem"></div>
                <div class="options">
                  <el-checkbox-group v-model="item.selected" :disabled="true">
                    <el-checkbox :label="option.identification" v-for="option in item.options" :key="option.identification" >
                      <div class="opts_no">{{option.identification}}. </div>
                      <div class="opts_content" v-html="option.option"></div>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="analysis">
                  <div class="answer">
                    <span class="error"><i class="el-icon-close"></i>回答错误</span>
                    <span>正确答案：{{ JSON.parse(item.answer).join(',') }}</span>
                  </div>
                  <div class="tip" v-if="item.analysis">
                    <el-tag size="medium">试题解析</el-tag>
                    <p v-html="item.analysis"></p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <Pagination :pager="errorPager" @getPager="getErrorPager" v-if="errorPager.total !== 0"></Pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import { anchorLinkTo } from 'utils/common.js'
  import { get } from "api/course/course/create"
  import { initExercisePage } from "api/course/course/study"
  import Pagination from 'components/common/Pagination'
  import { listPapaerQuestion } from "api/question/list"
  import { submitTest, addUserTag, clearPaperAnswer } from "api/question/list"
  import { checkUserCanStudy } from 'api/course/course/list'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        loaded: false,
        cur_uuid: '',
        cur_name: '',
        paper_id: '',
        title: '',
        activeName: 'total',
        singleChoice: [],
        multipleChoice: [],
        judgeChoice: [],
        errorSingleChoice: [],
        errorMultipleChoice: [],
        errorJudgeChoice: [],
        singleChoiceObj: [],
        multiChoiceObj: [],
        judgeObj: [],
        errorSingleChoiceObj: [],
        errorMultiChoiceObj: [],
        errorJudgeObj: [],
        pager: {
          total: 0,
          currentPage: 1,
          pageSize: 10
        },
        errorPager: {
          total: 0,
          currentPage: 1,
          pageSize: 10
        },
        answeredQuestionIds: [],
        questionNos: {
          singleChoiceNos: {},
          multiChoiceNos: {},
          judgeNos: {},
          errorSingleChoiceNos: {},
          errorMultiChoiceNos: {},
          errorJudgeNos: {},
        },
        userTags: [],
      }
    },
    created() {
      this.cur_uuid = this.$route.params.cur_uuid
      this.getCur()
    },
    mounted() {
      document.getElementsByTagName('body')[0].className = 'exercise_page';
      checkUserCanStudy({'cur_uuid': this.cur_uuid}).then(res => {
        if(!res.code) {
          this.$router.push(`/course/detail/${ this.cur_uuid }`)
        }
      })
    },
    unmounted () {
      document.body.removeAttribute('class')
    },
    methods: {
      getCur() {
        const that = this
        initExercisePage(this.cur_uuid).then(res => {
          if(res.code == 0){
            that.title = res.name
            that.singleChoiceObj = res.single_choices
            that.multiChoiceObj = res.multi_choices
            that.judgeObj = res.judge_list
            that.paper_id = res.paper_id
            that.setQuestionNos()
            that.listQuestions()
            that.pager.total = res.single_choices.length + res.multi_choices.length + res.judge_list.length
            that.userTags = res.user_tags
            //标签页title
            document.title = `做题-${ this.title }-未来网络学堂`;

            //reload 7moor js
            const s = document.createElement('script');
            s.type = 'text/JavaScript';
            s.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=a4c3d330-5dcc-11ea-b7ff-07dd5ece4b95&autoShow=true&language=ZHCN';
            document.body.appendChild(s);

          }
          else if(res.code == 1){
            this.goPath('/404')
          }
        })
      },
      listQuestions() {
        let filters = {
          paper_id: this.paper_id,
          offset: (this.pager.currentPage - 1) * this.pager.pageSize,
          limit: this.pager.pageSize,
          need_answer: true,
          total: true
        }
        if(this.activeName == 'wrong'){
          filters['is_right'] = false
        }
        const that = this
        listPapaerQuestion(this.paper_id, filters).then(res => {
          console.log(this.paper_id, filters)
          console.log(res)
          that.singleChoice = res.single_choice_list
          that.multipleChoice = res.multi_choice_list
          that.judgeChoice = res.true_false_list
          that.singleChoice.forEach(item => {
            if(item.user_answer) {
              item.selected = item.user_answer.answer
            }
          })
          that.multipleChoice.forEach(item => {
            if(item.user_answer) {
              item.selected = item.user_answer.answer.split(',')
            }
          })
          that.judgeChoice.forEach(item => {
            if(item.user_answer) {
              item.selected = item.user_answer.answer
            }
          })
          that.loaded = true;
        })
      },
      initErrorPage() {
        if(this.activeName == 'wrong'){
          const that = this
          let index = 0
          that.questionNos.errorSingleChoiceNos = {}
          that.questionNos.errorMultiChoiceNos = {}
          that.questionNos.errorJudgeNos = {}
          that.errorSingleChoiceObj = []
          that.errorMultiChoiceObj = []
          that.errorJudgeObj = []
          that.singleChoiceObj.forEach(item => {
            if(item.is_right === false){
              that.errorSingleChoiceObj.push(item)
              that.questionNos.errorSingleChoiceNos[item.id] = ++index
            }
          })
          index = 0
          that.multiChoiceObj.forEach(item => {
            if(item.is_right === false){
              that.errorMultiChoiceObj.push(item)
              that.questionNos.errorMultiChoiceNos[item.id] = ++index
            }
          })
          index = 0
          that.judgeObj.forEach(item => {
            if(item.is_right === false){
              that.errorJudgeObj.push(item)
              that.questionNos.errorJudgeNos[item.id] = ++index
            }
          })
          that.errorPager.total = that.errorSingleChoiceObj.length + that.errorMultiChoiceObj.length + that.errorJudgeObj.length
          that.errorPager.currentPage = 1
          that.getErrorQuestions()
        }
      },
      getErrorQuestions() {
        let filters = {
          paper_id: this.paper_id,
          offset: (this.errorPager.currentPage - 1) * this.errorPager.pageSize,
          limit: this.errorPager.pageSize,
          need_answer: true,
          is_right: false
        }
        const that = this
        listPapaerQuestion(this.paper_id, filters).then(res => {
          that.loaded = true
          that.errorSingleChoice = res.single_choice_list
          that.errorMultipleChoice = res.multi_choice_list
          that.errorJudgeChoice = res.true_false_list
          that.errorSingleChoice.forEach(item => {
            item.selected = item.user_answer.answer
          })
          that.errorMultipleChoice.forEach(item => {
            item.selected = item.user_answer.answer.split(',')
          })
          that.errorJudgeChoice.forEach(item => {
            item.selected = item.user_answer.answer
          })
        })
      },
      setQuestionNos() {
        const that = this
        that.singleChoiceObj.forEach((item, i) => {
          that.questionNos.singleChoiceNos[item.id] = i+1
        })
        that.multiChoiceObj.forEach((item, j) => {
          that.questionNos.multiChoiceNos[item.id] = j+1
        })
        that.judgeObj.forEach((item, k) => {
          that.questionNos.judgeNos[item.id] = k+1
        })
      },
      onSubmit(question){
        let is_right = false
        let answer = JSON.parse(question.answer).sort()
        let type = question.type_name
        let my_answer
        if(type == '单选题'){
          my_answer = question.selected
          if (my_answer === answer[0]){
            is_right = true
          }
        }
        else if (type == '多选题') {
          my_answer = question.selected.sort().join(',')
          if(my_answer == answer.join(',')){
            is_right = true
          }
        }
        else if(type == '判断题') {
          my_answer = question.selected
          if(question.selected == answer[0]){
            is_right = true
          }
        }

        let value = {
          paper_id: this.paper_id,
          question_id: question.id,
          // paper_question_id: question.paper_question_id,
          answer: my_answer,
          is_right: is_right
        }
        let data = {
          stem: question.text_stem,
          values: [value]
        }
        const that = this
        submitTest(data).then(res => {
          if(res.code == 0){
            question['user_answer'] = {
              is_right: is_right
            }
            if(type == '单选题'){
              that.singleChoiceObj.forEach(item => {
                if(item.id == question.id) {
                  item.is_right = is_right
                }
              })
            }
            else if(type == '多选题'){
              that.multiChoiceObj.forEach(item => {
                if(item.id == question.id) {
                  item.is_right = is_right
                }
              })
            }
            else if(type == '判断题'){
              that.judgeObj.forEach(item => {
                if(item.id == question.id) {
                  item.is_right = is_right
                }
              })
            }
          }
        })
      },
      goPath(path) {
        this.$router.push(path);
      },
      anchorLinkTo (type, id) {
        let ref = 'question_' + id;
        if(this.$refs[ref] == undefined){
          let no = 0
          if(type == 'judge') {
            no = this.questionNos.judgeNos[id]
          }
          else if(type == 'single') {
            no = Object.keys(this.questionNos.judgeNos).length + this.questionNos.singleChoiceNos[id]
          }
          else if(type == 'multi') {
            no = Object.keys(this.questionNos.judgeNos).length + Object.keys(this.questionNos.singleChoiceNos).length
              + this.questionNos.multiChoiceNos[id]
          }
          this.pager.currentPage = Math.ceil(no/this.pager.pageSize)
          this.loaded = false
          this.listQuestions()
        }
        else {
          this.loaded = true
        }
        const that = this
        this.$nextTick(()=>{
          let timer = setInterval(() => { console.log(that.loaded);
            if(that.loaded){
              clearInterval(timer)
              that.$refs[ref].scrollIntoView({
                behavior: "smooth", // 默认 auto
              });
            }
          }, 500)
        });
      },
      anchorErrorLinkTo (type, id) {
        let ref = 'question_' + id
        if(this.$refs[ref] == undefined){
          let no = 0
          if(type == 'judge') {
            no = this.questionNos.errorJudgeNos[id]
          }
          else if(type == 'single') {
            no = Object.keys(this.questionNos.errorJudgeNos).length + this.questionNos.errorSingleChoiceNos[id]
          }
          else if(type == 'multi') {
            no = Object.keys(this.questionNos.errorJudgeNos).length + Object.keys(this.questionNos.errorSingleChoiceNos).length
              + this.questionNos.errorMultiChoiceNos[id]
          }
          this.errorPager.currentPage = Math.ceil(no/this.pager.pageSize)
          this.loaded = false
          this.getErrorQuestions()
        }
        else {
          this.loaded = true
        }
        const that = this
        let timer = setInterval(() => {
          if(that.loaded){
            clearInterval(timer)
            that.$refs[ref].scrollIntoView({
              behavior: "smooth", // 默认 auto
            });
          }
        }, 500)
      },
      tagQuestion(question_id) {
        const that = this
        let data = {
          paper_id: this.paper_id,
          question_id: question_id
        }
        addUserTag(data).then(res => {
          if(res.code == 0){
            let index = that.userTags.indexOf(question_id)
            if(index >= 0){
              that.userTags.splice(index, 1)
            }
            else {
              that.userTags.push(question_id)
            }
          }
        })
      },
      restart() {
        const that = this
        clearPaperAnswer(that.paper_id).then(res => {
          if(res.code == 0){
            that.activeName = 'total'
            that.pager.currentPage = 1
            that.getCur()
          }
        })
      },
      getPager(pager) { //获取分页变化后数据
        this.pager = pager
        this.listQuestions()
      },
      getErrorPager(pager) { //获取分页变化后数据
        this.errorPager = pager
        this.getErrorQuestions()
      },
    },
  }
</script>

<style lang="scss">
  .exercise_page {
    #chatBtn {
      display:none !important;
    }
  }
</style>

<style lang="scss">
@import "~@/assets/css/ueditor.scss";
</style>
<style lang="scss" scoped>
  :deep(.header) {
    height:60px;
    line-height:60px;
    background:#009dd9;
    color:#fff;
    .el-page-header__left {
      background:#1aa7dd;
      margin-right:10px;
      .el-page-header__icon {
        width: 60px;
        justify-content: center;
      }
      &:after {
        display:none;
      }
      &:hover {
        background:#33B1E1;
      }
    }
    .el-page-header__content {
      color:#fff;
      font-size:16px;
    }
  }
  .content_wrap {
    display:flex;
    position:absolute;
    top:60px;
    left:0;
    right:0;
    bottom:0;
    background:#efefef;
    .left {
      width:400px;
      margin-right:20px;
      background:#fff;
      position:relative;
      :deep(.top_tabs) {
        .el-tabs__header {
          margin-bottom:0;
          .el-tabs__nav-wrap {
            padding-left:15px;
            padding-right: 15px;
            .el-tabs__item {
              height:50px;
              line-height:50px;
            }
            &:after {
              height:1px;
            }
          }
        }
        .el-tabs__content {
          position: absolute;
          top: 50px;
          bottom: 60px;
          right: 0;
          left: 0;
          overflow-y: auto;
        }
      }
      .bottom {
        height: 60px;
        background: #f5f7fa;
        border-top: 1px solid #E4E7ED;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding:0 15px;
        .el-button {
          margin-left:auto;
        }
      }
    }
    .right {
      flex:1;
      background:#fff;
      overflow-x: hidden;
      overflow-y: auto;
      padding:20px;
      h4 {
        font-size:12px;
        color:#333;
        background:#f8f8f8;
        margin-top:0;
        margin-bottom:15px;
        font-weight: normal;
        padding: 15px;
      }
      .exercise_list {
        > li {
          display: flex;
          margin-bottom:10px;
          .tag {
            margin-right:5px;
            i {
              font-size:16px;
              color:#c1c1c1;
              cursor: pointer;
              position:relative;
              top:2px;
              &.is_tag {
                color:#00adef;
              }
            }
          }
          .content {
            display:flex;
            line-height:1.5;
            flex:1;
            .no {
              width:40px;
              margin-right:10px;
              text-align:center;
            }
            .main {
              flex:1;
              :deep(.stem) {
                color:#333;
                margin-bottom:10px;
                p {
                  margin-top:0;
                }
              }
              :deep(.options) {
                .el-checkbox-group {
                  display:inline-block;
                }
                .el-radio, .el-checkbox {
                  display:flex;
                  margin-right:0;
                  margin-bottom: 15px;
                  /*margin-bottom:10px;*/
                  .el-radio__input, .el-checkbox__input {
                    padding-top:2px;
                  }
                  .el-radio__label, .el-checkbox__label {
                    font-size:12px;
                    white-space: normal;
                    line-height:1.5;
                    display:flex;
                    .opts_no {
                      margin-right:5px;
                    }
                    .opts_content {
                      p {
                        margin:0;
                      }
                      img {
                        max-width:100%;
                      }
                    }
                  }
                }
              }
              .btn {
                padding-bottom: 15px;
                padding-top: 5px;
              }
              .analysis {
                padding:15px;
                font-size:12px;
                background:#f8f8f8;
                .answer {
                  > span {
                    margin-right:20px;
                    > i {
                      margin-right:5px;
                    }
                    &.success {
                      color:#6ecd3f;
                    }
                    &.error {
                      color:#f51c21;
                    }
                  }
                }
                .tip {
                  margin-top:15px;
                  .el-tag {
                    margin-bottom:15px;
                  }
                  > p {
                    margin:0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .wrap {
    padding:15px;
    .title {
      margin-top:15px;
      margin-bottom:15px;
      font-weight: normal;
      color:#333;
    }
    .index {
      font-size:12px;
      color:#fff;
      background:#d1d1d1;
      border-radius: 2px;
      height:33px;
      line-height:33px;
      text-align:center;
      cursor: pointer;
      margin-bottom:15px;
      position:relative;
      overflow:hidden;
      > i {
        position:absolute;
        font-size:12px;
        right:1px;
        top:-2px;
        color:#009dd9;
        line-height:normal;
      }
      &.success, &.error {
        > i {
          color:#fff;
        }
      }
      &.success {
        background:#52c41a;
      }
      &.error {
        background:#f51c21;
      }

    }
  }

</style>
